/**
 * Styles for React Event Editor popup
 */
.popup {
  position      : absolute;
  left          : 25%;
  right         : 25%;
  top           : 25%;
  bottom        : 25%;
  margin        : auto;
  width         : 40em;
  background    : rgb(248, 249, 250);;
  box-shadow: 0 3px 6px rgba(0,0,0,.12), 0 3px 6px rgba(0,0,0,.24);
  height: max-content;
  display        : flex;
  flex-direction : column;

  header {
    font-size     : 2em;
    font-weight   : 700;
    background    : rgb(255, 245, 230);
    padding       : 0.5em;
    border-bottom : solid 1px rgb(254, 172, 49);
  }

  article {
    flex : 1;
    padding: 2em 1em 2em 1em
  }

  footer {
    text-align : center;
    padding-bottom : 1em;
    button {
      width         : 7em;
    }
    button:first-child {
      margin-right : 1em;
    }
  }
}
.release-list {
  max-height: 80px;
  overflow-y: scroll;
}
