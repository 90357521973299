/**
 * Styles for React Event Editor popup
 */
$height: 25em;
.popup-mask {
  position         : fixed;
  z-index          : 5;
  width            : 100%;
  height           : 100%;
  top              : 0;
  left             : 0;
  right            : 0;
  bottom           : 0;
  margin           : auto;
  background-color : rgba(0,0,0, 0.5);
}
.popup {
  position      : absolute;
  left          : 25%;
  right         : 25%;
  top           : 25%;
  bottom        : 25%;
  margin        : auto;
  width         : 40em;
  background    : rgb(248, 249, 250);;
  box-shadow: 0 3px 6px rgba(0,0,0,.12), 0 3px 6px rgba(0,0,0,.24);

  display        : flex;
  flex-direction : column;

  header {
    font-size     : 2em;
    font-weight   : 700;
    background    : rgb(255, 245, 230);
    padding       : 0.5em;
    border-bottom : solid 1px rgb(254, 172, 49);
  }

  article {
    flex : 1;
    padding : 1em;
  }

  footer {
    text-align : center;
    padding-bottom : 1em;
    button {
      width         : 7em;
    }
    button:first-child {
      margin-right : 1em;
    }
  }
}
.release-list {
  max-height: 140px;
  overflow-y: scroll;
}
.hr-text {
  margin-top: 30px;
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: .5;
  &:before {
    content: '';
    // use the linear-gradient for the fading effect
    // use a solid background color for a solid bar
    background:  #818078;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: black;

    padding: 0 .5em;
    line-height: 1.5em;
    // this is really the only tricky part, you need to specify the background color of the container element...

    background-color: #fcfcfa;
  }
}
