@import '~@bryntum/scheduler/scheduler.stockholm.css';
#root {
    height: 100vh;
    width: 100%;
    display: block;
    background-image: url("img/bg.png");
    background-size: 30%, 30%;
    background-repeat: repeat;
}
.container {
    position: relative;
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    transform: translate(0, 0);
    overflow: hidden;
    height: 100%;
}
.demo-header__calendar {
    padding: 1em!important;
    justify-content: flex-end;
}
.login-content__btn {
    display: block;
    color: white;
    border-color: #796eff;
    background-color:  #796eff;
    margin: 0 auto;
}
.login-content__btn:hover, .login-content__btn:active  {
    border-color: #635ac7!important;
    background-color:  #635ac7!important;
}
.b-button:disabled {
    border-color: rgba(120,120,131,.4)!important;
    background-color:  #B9B6C7!important;
}
.demo-header__greetings {
    margin: 0 20px 0 0;
    padding-top: 8px;
    font-weight: normal;
    font-size: 1.3em;
    vertical-align: middle;
}

.login-content {
    background: #fff;
    box-shadow: 0 1px 11px rgba(0, 0, 0, 0.27);
    border-radius: 2px;
    width: 380px;
    display: block;
    margin: 10% auto 0;
    vertical-align: middle;
    position: relative;
    padding: 35px;
}
.login-content__header {
    text-align: center;
}
.popap_error {
    bottom: 50%!important;
    overflow-y: hidden!important;
    left: 50%!important;
    right: auto!important;
    transform:translate(-50%, -50%)!important;
    padding: 15px!important;
    background-color: #feac31!important;
}
.b-sch-foreground-canvas > svg {
    top: 10px!important;
}
div >.b-sch-label-left {
    align-self: center!important;
    transform: translate3d(calc(0%), -6px, 0)!important;
}
div >.b-sch-label-left-release-parent {
    transform: translate3d(calc(-50%), -6px, 0)!important;

    /*align-self: flex-start!important;*/

}
.current-time-line {
    border-left-color: #19345e!important;
}
.current-time-line > label {
    background-color: #19345e!important;
    color: white;
}
.b-grid-subgrid .fika {
    background: rgba(217, 231, 246, 0.6);
    color: #926b36;
}
.b-grid-subgrid .fika label:before {
    font-family: "Font Awesome 5 Free", serif;
    font-weight: 900;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    content: "";
    margin-right: 5px;
}
.b-sch-event-content {
  color: black;
}
.navbar__logo {
    background-image: url("logo.png");
    background-repeat: no-repeat;
    background-size: 80px 45px;
    width: 100px;
    height: 45px;
    cursor: pointer;
}

.navbar__asana_button {
    background-image: url("img/Asana_Icon.png");
    background-repeat: no-repeat;
    background-size: 45px 45px;
    width: 45px;
    height: 45px;
    cursor: pointer;
}

.navbar__calendar_button {
    background-image: url("img/Calendar_Icon.png");
    background-repeat: no-repeat;
    background-size: 45px 45px;
    width: 45px;
    height: 45px;
    cursor: pointer;
}

.navbar__logs_button {
    background-image: url("img/logs.png");
    background-repeat: no-repeat;
    background-size: 45px 45px;
    width: 45px;
    height: 45px;
    cursor: pointer;
}
.sync_tasks_status__from_calendar_status {
    background-image: url("img/Calendar_Icon.png");
    background-repeat: no-repeat;
    background-size: 23px 23px;
    width: 23px;
    height: 23px;
    cursor: pointer;
    margin: 0;
}
.sync_tasks_status__from_asana_status {
    background-image: url("img/Asana_Icon.png");
    background-repeat: no-repeat;
    background-size: 23px 23px;
    width: 23px;
    height: 23px;
    cursor: pointer;
    margin: 0;
}
.help_button__from_asana_icon {
    background-image: url("img/Asana_Icon.png");
    background-repeat: no-repeat;
    background-size: 25px 25px;
    width: 25px;
    height: 25px;
}
.help_button__from_calendar_icon {
    background-image: url("img/Calendar_Icon.png");
    background-repeat: no-repeat;
    background-size: 25px 25px;
    width: 25px;
    height: 25px;
}

.sync_popup__blue_gradient {
    background-image: linear-gradient(90deg, #4285ec, #4ac5f3);
}
.sync_popup__red_gradient {
    background-image: linear-gradient(90deg, #f55966, #fd9757);
}



.progress__asana_logo {
    background-image: url("img/asana.png");
    background-repeat: no-repeat;
    background-size: 22px 22px;
    width: 22px;
    height: 22px;
    cursor: pointer;
    display: inline-block;
}

.b-sch-event-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.b-sch-event-header,
.b-sch-event-footer {
    display: flex;
    flex: 1;
    align-items: center;
}
.b-sch-event-footer {
    padding: 0;
    margin: 0;
}

.makeStyles-root-4 > div {
    margin-block: auto;
}
.b-float-root > .b-floating.b-toast {
    right: auto;
}

.b-stickyevents.b-sch-horizontal .b-sch-event-wrap:not(.b-disable-sticky) .b-sch-event-content, .b-stickyevents.b-sch-horizontal .b-sch-resourcetimerange .b-sch-event-content {
    position: sticky;
    left: auto;
}


.b-stickyevents.b-sch-horizontal .b-sch-event-wrap:not(.b-disable-sticky),
.b-stickyevents.b-sch-horizontal .b-sch-resourcetimerange {
    overflow: visible;
}

.b-stickyevents .b-sch-event-content {
    flex: none;
}

.b-stickyevents.b-sch-horizontal .b-sch-event-wrap:not(.b-disable-sticky) .b-sch-event,
.b-stickyevents.b-sch-horizontal .b-sch-resourcetimerange .b-sch-event {
    overflow: visible;
}
.b-sch-event.b-sch-event-resizable-true,.b-sch-event.b-sch-event-resizable-false,
.b-sch-event-wrap.b-sch-style-colored .b-sch-event{
    overflow: hidden !important;
}
.b-sch-style-line .b-sch-event {
    overflow: visible !important;

}

.b-stickyevents.b-sch-horizontal .b-sch-resourcetimerange {
    justify-content: flex-start;
    overflow: visible
}

.b-stickyevents.b-sch-horizontal .b-sch-resourcetimerange > div {
    overflow: hidden;
    width: 100%
}

.b-firefox.b-stickyevents.b-sch-horizontal .b-sch-foreground-canvas {
    contain: none
}

.b-safari.b-stickyevents.b-sch-horizontal.b-dragging-event .b-sch-foreground-canvas {
    overflow: hidden
}

.b-safari.b-stickyevents.b-sch-horizontal .b-sch-foreground-canvas {
    overflow: hidden
}

.b-safari.b-stickyevents.b-sch-horizontal .b-sch-foreground-canvas .b-sch-event.b-sch-event-endsoutside {
    width: 100%
}

.b-safari.b-stickyevents.b-sch-horizontal .b-sch-foreground-canvas .b-sch-event.b-sch-event-endsoutside .b-sch-event-content {
    margin-inline-end: 0
}

.b-sch-event-content {
    display: flex;
}
.css-x15wq9 {
    position: unset!important;
}

.b-grid-header .b-sch-timerange.selected-time-range {
    background-color: #98c1ff99;
}

.b-grid-header .b-sch-timerange.fika {
    background-color: #98c1ff99;
}


.b-grid-header .b-sch-timerange.time-range {
    background-color: rgba(248, 127, 172, 0.6);
}

.b-grid-header .b-sch-timerange.b-sch-line {
    background-color: #19345e!important;
}

.b-sch-timerange.time-range {
    background-color: rgba(248, 127, 172, 0.1);
}

.custom-label {
    z-index: 100;
    position: absolute;
    display: block;
    bottom: -8px;
    left: 0;
    border-radius: 3px;
    font-size: 8px !important;
    padding: 0 5px 0 5px;
    line-height: 10px;
    opacity: 0.8;
    border: 1px #ffffff solid;
}

.red {
    background: red;
    color: black;
}

/* Ярко-желтый */
.brightyellow {
    background-color: #FFFACD; /* LemonChiffon для бледного фона */
    color: #D4A017; /* GoldenRod для темного текста */
}
/* Светло-желтый */
.lightyellow {
    background-color: #FFFFE0; /* LightYellow для бледного фона */
    color: #BCB88A; /* DarkKhaki для темного текста */
}
/* Темно-красный */
.darkred {
    background-color: #FFCCD4; /* LightPink для бледного фона */
    color: #8B0000; /* DarkRed для темного текста */
}
/* Бордовый */
.burgundy {
    background-color: #F2D7D5; /* RosyBrown Light для бледного фона */
    color: #800020; /* Burgundy для темного текста */
}
/* Светло-красный */
.lightred {
    background-color: #FABEBE; /* MistyRose для бледного фона */
    color: #B22222; /* FireBrick для темного текста */
}
/* Ярко-оранжевый */
.brightorange {
    background-color: #FFE5B4; /* PeachPuff для бледного фона */
    color: #FF8C00; /* DarkOrange для темного текста */
}
/* Фиолетовый */
.purple {
    background-color: #E6E6FA; /* Lavender для бледного фона */
    color: #800080; /* Purple для темного текста */
}
/* Светло-розовый */
.lightpink {
    background-color: #FFEFFF; /* Pink Light для бледного фона */
    color: #FF1493; /* DeepPink для темного текста */
}
/* Малиновый */
.raspberry {
    background-color: #FFE4E1; /* MistyRose для бледного фона */
    color: #E30B5D; /* Raspberry для темного текста */
}
/* Темно-синий */
.darkblue {
    background-color: #ADD8E6; /* LightBlue для бледного фона */
    color: #00008B; /* DarkBlue для темного текста */
}
/* Светло-синий */
.lightblue {
    background-color: #BFEFFF; /* DeepSkyBlue Light для бледного фона */
    color: #1E90FF; /* DodgerBlue для темного текста */
}
/* Темно-зеленый */
.darkgreen {
    background-color: #90EE90; /* LightGreen для бледного фона */
    color: #006400; /* DarkGreen для темного текста */
}
/* Светло-зеленый */
.lightgreen {
    background-color: #F0FFF0; /* Honeydew для бледного фона */
    color: #32CD32; /* LimeGreen для темного текста */
}
/* Мятный */
.mint {
    background-color: #F5FFFA; /* MintCream для бледного фона */
    color: #3EB489; /* MediumAquamarine для темного текста */
}
/* Бирюзовый */
.turquoise {
    background-color: #AFEEEE; /* PaleTurquoise для бледного фона */
    color: #30D5C8; /* Turquoise для темного текста */
}
/* Светло-коричневый */
.lightbrown {
    background-color: #F5F5DC; /* Beige для бледного фона */
    color: #8B4513; /* SaddleBrown для темного текста */
}
/* Ореховый */
.walnut {
    background-color: #DCD0C0; /* BurlyWood Light для бледного фона */
    color: #5F4B32; /* Walnut для темного текста */
}
/* Бежевый */
.beige {
    background-color: #F5F5DC; /* Beige для бледного фона */
    color: #A9A9A9; /* DarkGray для темного текста */
}
/* Средне-серый */
.mediumgray {
    background-color: #D3D3D3; /* LightGrey для бледного фона */
    color: #696969; /* DimGray для темного текста */
}
/* Золотистый */
.golden {
    background-color: #FFFACD; /* LemonChiffon для бледного фона */
    color: #FFD700; /* Gold для темного текста */
}

.nightblue {
    background: #004285;
    color: #ffffff;
}


.custom-label.dot {
    width: 5px;
    left: 3px;
    bottom: -2px;
    display: block;
    border-radius: 50%;
    border: none;
}