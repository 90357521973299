
/*.popup-note {
  height: 15em;
  min-width: 20em;
}*/
.popup-note {
  top           : 50%;
  left          : 50%;
  max-height:   600px;
  overflow-y: auto;
  //height        : 15em;
  position      : absolute;
//https://habr.com/ru/post/189696/
  -webkit-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  //width: 50%;
  margin        : auto;
  min-width     : 23em;



  background    : rgb(248, 249, 250);
  box-shadow: 0 3px 6px rgba(0,0,0,.12), 0 3px 6px rgba(0,0,0,.24);

  display        : flex;
  flex-direction : column;

  header {
    font-size     : 2em;
    font-weight   : 700;
    background    : rgb(255, 245, 230);
    padding       : 0.5em;
    border-bottom : solid 1px rgb(254, 172, 49);
  }
  form {
  }

  article {
    flex : 1;
    padding : 1em;
  }
  section {
  }

  footer {
    text-align : center;
    padding-bottom : 1em;
    button {
      width         : 7em;
    }
    button:first-child {
      margin-right : 1em;
    }
  }
}
.popup-note-textarea {
  margin: 10px 5px 10px 5px;
  min-height: 5em;
  max-height: 5em;
  overflow-y: scroll!important;
  resize: none;
}
